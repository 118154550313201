document.addEventListener('DOMContentLoaded', function () {
	var elems = document.querySelectorAll('.carousel');
	var options = {
		dist: -250,
	};
	var instances = M.Carousel.init(elems, options);

	const animElms = document.querySelectorAll('div');

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			console.log(entry.target.id, entry.intersectionRatio);
			const animated = entry.target.classList.contains('animate');
			if (entry.intersectionRatio > 0) {
				if (!animated) {
					entry.target.classList.add('animate');
					const p_tags = entry.target.getElementsByTagName('p');
					const p_tags_arr = [...p_tags];
					p_tags_arr.map((tag) => {
						tag.classList.add('move-up-3');
					});
				}
			} else {
				if (animated) {
					entry.target.classList.remove('animate');
					const p_tags = entry.target.getElementsByTagName('p');
					const p_tags_arr = [...p_tags];
					p_tags_arr.map((tag) => {
						tag.classList.remove('move-up-3');
					});
				}
			}
		});
	});

	animElms.forEach((el) => {
		observer.observe(el);
	});
});
